import React from 'react';

import { Disclosure } from '@headlessui/react';

import Logo from './Logo';
import MenuButton from './MenuButton';
import {
  DesktopNavigation,
  MobileNavigation,
} from './Navigation';

const Header = () => (
  <Disclosure as="nav" className="bg-header pl-6 md:pl-0 sticky top-0 z-50">
    {({ open }) => (
      <>
        <div className="max-w-7xl w-auto mx-auto px-2 md:px-6 lg:px-8 h-30 mb-4 md:mb-0">
          <div className="flex items-center h-30 justify-between"></div>
          <MenuButton open={open} />
          <div className="flex-1 flex items-center justify-evenly md:justify-between">
            <Logo />
            <DesktopNavigation />
          </div>
        </div>

        <MobileNavigation />
      </>
    )}
  </Disclosure>
);
export default Header;
