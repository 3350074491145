import React from 'react';
import { NavLink } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import LogoutButton from '../components/LogoutButton';
import LoginButton from '../components/LoginButton'; // Import the LoginButton
import { useIsAuthenticated } from "@azure/msal-react";

const navigation = [
   { name: 'LotW', href: '/lotw' },
  { name: "Eye Info", href: '/eyeshapeinfo'},
  // { name: "TikTokers", href: '/tiktokers'},
  // { name: 'Posts', href: '/posts/new' },
  { name: 'Profile', href: '/profile' },
   { name: 'PastLooks', href: '/pastlooks'}
];

const MobileNavigation = () => {
  const isAuthenticated = useIsAuthenticated(); // Hook to check if the user is authenticated

  return (
    <Disclosure.Panel className="block md:hidden">
      <div className="ml-2 px-2 pt-2 pb-3 space-y-1 text-gray-300">
        {navigation.map((item) => (
          <Disclosure.Button
            key={item.name}
            as={NavLink}
            to={item.href}
            end={item.exact}
            className={({ isActive }) =>
              isActive ? 'btn-nav-active' : 'btn-nav'
            }
            aria-current="page"
          >
            {item.name}
          </Disclosure.Button>
        ))}
        {isAuthenticated ? <LogoutButton /> : <LoginButton />} {/* Conditional rendering based on authentication status */}
      </div>
    </Disclosure.Panel>
  );
};

const DesktopNavigation = () => {
  const isAuthenticated = useIsAuthenticated(); // Hook to check if the user is authenticated

  return (
    <div className="hidden md:flex space-x-4 text-gray-300">
      {navigation.map((item) => (
        <NavLink
          key={item.name}
          to={item.href}
          end={item.exact}
          className={({ isActive }) => (isActive ? 'btn-nav-active' : 'btn-nav')}
          aria-current="page"
        >
          {item.name}
        </NavLink>
      ))}
      {isAuthenticated ? <LogoutButton /> : <LoginButton />} {/* Conditional rendering based on authentication status */}
    </div>
  );
};

export { DesktopNavigation, MobileNavigation };
