import React, { useEffect, useState } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../authConfig'; // Adjust the path accordingly

const Profile = () => {
    const [userName, setUserName] = useState('');

    useEffect(() => {
        const msalInstance = new PublicClientApplication(msalConfig);

        // Attempt to get the user's account info from the cache
        const accounts = msalInstance.getAllAccounts();

        if (accounts && accounts.length > 0) {
            // Account info could be retrieved from cache
            setUserName(accounts[0].username); // or .name depending on the details provided by MSAL
        } else {
            // Handle scenario when user's account info is not available in cache.
            // Possibly redirect user to login page.
        }

    }, []);

    return (
        <div>
            <h1>Hello, {userName}!</h1>
            {/* Rest of your component */}
        </div>
    );
};

export default Profile;
