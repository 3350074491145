import React from 'react';
import Container from '../layout/Container';
import LookOfTheDay from "../components/LookOfTheDay";
import PostStream from "../components/PostStream";  

const Home = () => (
  <div className="flex flex-col items-center justify-center bg-gray-100 min-h-screen py-6">
    <h1 className="text-4xl font-bold mb-8">Welcome to LiveFreeMakeup</h1>
    <Container>
      <LookOfTheDay />
      <PostStream />  
    </Container>
  </div>
);

export default Home;

