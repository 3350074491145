// eyeShapesData.js

export const eyeShapes = [
    {
        name: "Almond",
        description: "Almond-shaped eyes are characterized by a slightly upswept lift at the outer corners and a narrowing at the inner corners, resembling the shape of an almond.",
        position: [
            {
                name: "Wide set",
                description: "Wide set position description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/almond_wideset.jpg?sp=r&st=2023-10-24T04:13:11Z&se=2033-10-24T12:13:11Z&spr=https&sv=2022-11-02&sr=b&sig=Oh2X1p36AZFpA5Xw%2F5MTYm7oDR9bhixMTs3jRd9Lu0s%3D"
            },
            {
                name: "Close set",
                description: "Close set position description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/almond_closeset.jpg?sp=r&st=2023-10-24T04:28:30Z&se=2033-10-24T12:28:30Z&spr=https&sv=2022-11-02&sr=b&sig=eNBP7yATe1oA%2BILLdSGS41%2BsihQJwFnr6bae69V6C%2FU%3D"
            },
        ],
        setting: [
            {
                name: "Deep set",
                description: "Deep set setting description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/almond-deep.jpg?sp=r&st=2023-06-02T17:02:43Z&se=2030-06-03T01:02:43Z&spr=https&sv=2022-11-02&sr=b&sig=x%2FD8zv%2FjOnOLlBLmG%2BIbHA9tF6SjcDtai9jnsGXZvAk%3D"
            },
            {
                name: "Protruding",
                description: "Protruding setting description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/almond-protruding.jpg?sp=r&st=2023-06-02T17:05:52Z&se=2030-06-03T01:05:52Z&spr=https&sv=2022-11-02&sr=b&sig=0x%2BB9voKsw1FiV1DWcbWg5Q82r3tSX9l%2BdBbu3%2Fk2Oo%3D"
            },
         
        ],
        imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/almond_eyes.jpg?sp=r&st=2023-06-02T16:19:16Z&se=2030-06-03T00:19:16Z&spr=https&sv=2022-11-02&sr=b&sig=3D2DzMHaqF0vPrI8iAQUhazE2NGzE4axcHI%2F%2BAPla5U%3D"
    },
    {
        name: "Downturned",
        description: "Down-turned eyes feature a downward tilt at the outer corners, giving the appearance of a natural droop or a melancholic expression.",
        position: [
        
            {
                name: "Wide set",
                description: "Wide set position description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/downturned_wideset.jpg?sp=r&st=2023-10-24T03:36:45Z&se=2033-10-24T11:36:45Z&spr=https&sv=2022-11-02&sr=b&sig=Z%2FjygEWTyp6LQtbT9ju5njmzRRhuSzULZndACaNv%2B2o%3D"
            },
            {
                name: "Close set",
                description: "Close set position description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/downturned.jpg?sp=r&st=2023-05-28T09:16:57Z&se=2030-05-28T17:16:57Z&spr=https&sv=2022-11-02&sr=b&sig=uTxn6IPO4m%2BN27%2BTUsLxd3o%2Bu5KDHuPcApr%2FrN0Ey34%3D"
            },
        ],
        setting: [
            {
                name: "Deep set",
                description: "Deep set setting description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/downtured_deepset.jpg?sp=r&st=2023-09-28T22:18:06Z&se=2033-09-29T06:18:06Z&spr=https&sv=2022-11-02&sr=b&sig=coIl00ru3C0oJamVURyvDqK2FF5lm6dwBHuFOZF8QKs%3D"
            },
            {
                name: "Protruding",
                description: "Protruding setting description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/downtured_protruding.jpg?sp=r&st=2023-09-28T22:18:48Z&se=2033-09-29T06:18:48Z&spr=https&sv=2022-11-02&sr=b&sig=hdjmy9H3J5z5sGxWI1zllsyfmOqipSrYYKXCGfb351U%3D    "
            },
         
        ],
        imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/downturned_default.jpg?sp=r&st=2023-10-24T03:48:04Z&se=2033-10-24T11:48:04Z&spr=https&sv=2022-11-02&sr=b&sig=wvDCdxmo%2BncptDkZZdhHGhuAB1xbraWE4GLRVYN5fl8%3D"
    },
    {
        name: "Hooded",
        description: "Hooded eyes are characterized by a fold of skin that descends from the brow bone to the lash line, partially or fully obscuring the eyelid in a relaxed neutral expression.",
        position: [
          
            {
                name: "Wide set",
                description: "Wide set position description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/hooded_wideset.jpg?sp=r&st=2023-09-28T22:20:32Z&se=2033-09-29T06:20:32Z&spr=https&sv=2022-11-02&sr=b&sig=8tLj2tKKDiHehdQEyP4dDLfXWXKUQ77CGYK34sc80mE%3D"
            },
            {
                name: "Close set",
                description: "Close set position description...",
                imageUrl: "url_to_your_image"
            },
        ],
        setting: [
            {
                name: "Deep set",
                description: "Deep set setting description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/hooded_deepset.jpg?sp=r&st=2023-10-24T04:00:52Z&se=2033-10-24T12:00:52Z&spr=https&sv=2022-11-02&sr=b&sig=P73%2F%2FgYWD1MCGFfIKF6e3SP%2BcNJu%2B0aGhk%2B7jvZcvoE%3D"
            },
            {
                name: "Protruding",
                description: "Protruding setting description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/hooded_protruding.jpg?sp=r&st=2023-10-24T04:42:20Z&se=2033-10-24T12:42:20Z&spr=https&sv=2022-11-02&sr=b&sig=5jXDK0BgkQHGtBe1Y4yXk1zXwu8qqwUTcI7QqWF1LVw%3D"
            },
        
        ],
        imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/hooded.jpg?sp=r&st=2023-05-28T09:26:47Z&se=2030-05-28T17:26:47Z&spr=https&sv=2022-11-02&sr=b&sig=7ZOO0HF0hBpbqGBQZBRtzWw3QiyZXJVFhoUEsvkWtOE%3D"
    },
    {
        name: "Upturned",
        description: "Upturned eyes, also known as cat eyes, have a natural lift at the outer corners, usually higher than almond shaped eyes",
        position: [
        
            {
                name: "Wide set",
                description: "Wide set position description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/upturned_wideset.jpg?sp=r&st=2023-10-24T03:33:47Z&se=2033-10-24T11:33:47Z&spr=https&sv=2022-11-02&sr=b&sig=amGtDrW10WtcHg1a3Llw1GXwLUBF0MO1rDj9QQB1OUg%3D"
            },
            {
                name: "Close set",
                description: "Close set position description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/upturned_closeset.jpg?sp=r&st=2023-10-24T03:33:14Z&se=2033-10-24T11:33:14Z&spr=https&sv=2022-11-02&sr=b&sig=Gnb2RybKxRah8wTymQ7Rt25tBNY2Qz3ScnOklX2bam4%3D"
            },
        ],
        setting: [
            {
                name: "Deep set",
                description: "Deep set setting description...",
                imageUrl: "url_to_your_image"
            },
            {
                name: "Protruding",
                description: "Protruding setting description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/upturned_protruding.jpg?sp=r&st=2023-09-28T22:24:42Z&se=2033-09-29T06:24:42Z&spr=https&sv=2022-11-02&sr=b&sig=KE753EO4EDa0A%2BQMAwflgAmGoPeJv4zhp9CIBsiqy%2Bc%3D"
            },
      
        ],
        imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/upturned.jpg?sp=r&st=2023-10-24T03:25:38Z&se=2033-10-24T11:25:38Z&spr=https&sv=2022-11-02&sr=b&sig=xOjsjXoc2jI3bft1%2B%2BoYbA8ehIw34mzeUZcca58BjwU%3D"
    },
    {
        name: "Monolid",
        description: "Monolid eyes are flat on the surface and do not have much of a crease, if any, presenting a flat, seamless area from the lash line to the brow. often seen in (but not limited to) Asian ethnicities.",
        position: [
          
            {
                name: "Wide set",
                description: "Wide set position description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/monolid_wideset.jpg?sp=r&st=2023-10-24T03:54:05Z&se=2033-10-24T11:54:05Z&spr=https&sv=2022-11-02&sr=b&sig=J0uzuRWkY%2F%2B%2FBtouWPNZXsuNh20RmMDyiZ8KvbW%2Ff5k%3D"
            },
            {
                name: "Close set",
                description: "Close set position description...",
                imageUrl: ""
            },
        ],
        setting: [
            {
                name: "Deep set",
                description: "Deep set setting description...",
                imageUrl: "url_to_your_image"
            },
            {
                name: "Protruding",
                description: "Protruding setting description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/monolid_protruding.jpg?sp=r&st=2023-10-24T03:52:33Z&se=2033-10-24T11:52:33Z&spr=https&sv=2022-11-02&sr=b&sig=IKYuqYBAH0P0s%2FJVjnkIv1keHVEW6L1fyKiaaEAN0zQ%3D"
            },
        
        ],
        imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/monolid.jpg?sp=r&st=2023-05-28T09:27:18Z&se=2030-05-28T17:27:18Z&spr=https&sv=2022-11-02&sr=b&sig=PrFuDeav6zoF9HrGDnMZpBD5K1T6p0QqXQMto%2BpCERw%3D"
    },
    {
        name: "Round",
        description: "Round eyes are characterized by their circular shape and large size, making the sclera prominently visible above and below the iris.",
        position: [
          
            {
                name: "Wide set",
                description: "Wide set position description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/round_wideset.jpg?sp=r&st=2023-09-28T22:23:27Z&se=2033-09-29T06:23:27Z&spr=https&sv=2022-11-02&sr=b&sig=oJZ3cuNahjHAufLuiHbc%2FszyxjAhubMndD4VTJosxcE%3D"
            },
            {
                name: "Close set",
                description: "Close set position description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/round_closeset.jpg?sp=r&st=2023-09-28T22:21:43Z&se=2033-09-29T06:21:43Z&spr=https&sv=2022-11-02&sr=b&sig=%2Bn2vjHzKpWlNJd9zf2cx16artolb2hY4jyw8YxlVpkc%3D"
            },
        ],
        setting: [
            {
                name: "Deep set",
                description: "Deep set setting description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/round_deepset.jpg?sp=r&st=2023-09-28T22:22:24Z&se=2033-09-29T06:22:24Z&spr=https&sv=2022-11-02&sr=b&sig=X%2BICrEZZfKfrNoaeYHpibEg8ivbV0x3VLsRbj4ltx8k%3D"
            },
            {
                name: "Protruding",
                description: "Protruding setting description...",
                imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/round_protruding.jpg?sp=r&st=2023-10-24T03:49:26Z&se=2033-10-24T11:49:26Z&spr=https&sv=2022-11-02&sr=b&sig=Z0DoffROH72iHD6hXqkrUuEJo748gb%2Fl2vc0wqyf52w%3D"
            },
         
        ],
        imageUrl: "https://dmselfmakedev1332187886.blob.core.windows.net/eyeshapes/round.jpg?sp=r&st=2023-05-28T09:15:16Z&se=2030-05-28T17:15:16Z&spr=https&sv=2022-11-02&sr=b&sig=q44oJs9j4UOijwhannb5ZLNzklAdc8op%2B0K7TbzPq70%3D"
    }
];
