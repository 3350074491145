import React from 'react';

import { Outlet } from 'react-router-dom';

import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';

const ProtectedPage = () => (
  <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
    <Outlet />
  </MsalAuthenticationTemplate>
);

export default ProtectedPage;
