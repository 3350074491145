import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  useAccount,
  useMsal,
} from '@azure/msal-react';

const uri = `${window._env_.API_URI}/Posts`;

const Upload = () => {
  const [photo, setPhoto] = useState();
  const [description, setDescription] = useState("");
  const [userInfo, setUserInfo] = useState();
  const [showPopup, setShowPopup] = useState(false); // Popup state
  const navigate = useNavigate();

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const postform = async (e) => {
    e.preventDefault();
    try {
      instance.acquireTokenSilent({
        account: account
      }).then((response) => {
        if(response) {
          const fd = new FormData();
          fd.append("Image", photo);
          const post = {
            Description: description,
            UserId: userInfo?.username
          };
          fd.append("Post", JSON.stringify(post));
          const token = response.idToken;

          return fetch(uri, {
            method: "POST",
            headers: {
              'Authorization': 'Bearer ' + token
            },
            body: fd
          });
        }
      }).then((response) => {
        const data = response.json();
        console.log(data);

        // Once the fetch completes, show the popup
        setShowPopup(true);

        // Hide the popup automatically after 3 seconds
        setTimeout(() => {
          setShowPopup(false);
        }, 6000);
      });

    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 py-3 sm:px-3 lg:px-4">
      {/* The popup */}
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow-lg">
            <p>Thank you for your submission! If your submission is approved you will see it in the home page</p>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}

      <div className="max-w-xs w-full space-y-8">
        <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Submit your entry for the Look of the Day</h1>
        <form className="mt-8 space-y-6" onSubmit={postform}>
          <fieldset>
            <legend className="font-medium text-gray-900">Make sure your photo is clear</legend>
            <div className="mt-2">
              <input
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                type="file"
                name="name"
                onChange={e => setPhoto(e.target.files[0])}
              />
            </div>
          </fieldset>

          <fieldset>
            <legend className="font-medium text-gray-900">Which products did you use? Any substitutions? </legend>
            <div className="mt-2">
              <input
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                type="text"
                name="description"
                onChange={e => setDescription(e.target.value)}
              />
            </div>
          </fieldset>
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Upload;
