
import React, { useState, useEffect } from "react";
import LookCard from '../components/LookCard';

const lookApiBaseUri = `${window._env_.API_URI}/looksoftheday`;

const LookOfTheDay = () => {
    const [look, setLook] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`${lookApiBaseUri}/GetMostRecentLook`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setLook(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setError(error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <p className="text-center text-gray-500">Loading...</p>;
    }

    if (error) {
        return <p className="text-center text-red-500">Error loading Look of the Day: {error.message}</p>;
    }

    if (!look) {
        return <p className="text-center text-gray-500">No Look of the Day available</p>;
    }

    return (
        <div className="flex flex-col items-center justify-center bg-white p-6 rounded-lg shadow-md mb-8">
            <h1 className="text-2xl font-bold mb-5">Look of the Day</h1>
            <LookCard look={look} />
        </div>
    );
};

export default LookOfTheDay;