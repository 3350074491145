import React from 'react';
import { eyeShapes } from './eyeShapesData';
import EyeDetail from './EyeDetail';

const EyeShapes = () => {
    return (
        <div className="grid grid-cols-3 gap-4 m-4">
            {eyeShapes.map((eyeShape, idx) => (
                <div key={idx} className="border p-4 rounded shadow">
                    <img className="h-32 w-full object-cover" src={eyeShape.imageUrl} alt={eyeShape.name} />
                    <div className="p-4">
                        <h2 className="text-xl font-bold">{eyeShape.name}</h2>
                        <p className="mt-2 text-sm">{eyeShape.description}</p>
                        <div className="flex flex-col"> {/* Change here */}
                            <div className="bg-white p-2 rounded shadow-sm mb-2"> {/* Add margin-bottom */}
                                <h3 className="text-md font-semibold">Position:</h3>
                                {eyeShape.position.map((pos, idx) => (
                                    <EyeDetail key={idx} detail={pos} className="custom-image"/>
                                ))}
                            </div>
                            <div className="bg-white p-2 rounded shadow-sm">
                                <h3 className="text-md font-semibold">Setting:</h3>
                                {eyeShape.setting.map((set, idx) => (
                                    <EyeDetail key={idx} detail={set} className="custom-image"/>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default EyeShapes;


