import React from 'react';
import EyeShapes from '../components/EyeShapes';

const EyeShapeInfo = () => (
  <div>
    {/* This is just for now and will change to appropriate title */}
    <h1>About the Eyes</h1> 
    <EyeShapes></EyeShapes>
    

  </div>
);

export default EyeShapeInfo;
