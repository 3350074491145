
// PastLooks.js
import React, { useState, useEffect } from "react";
import LookCard from '../components/LookCard';

const lookApiBaseUri = `${window._env_.API_URI}/looksoftheday`;

const PastLooks = () => {
    const [looks, setLooks] = useState([]);

    useEffect(() => {
        fetch(lookApiBaseUri + '/GetAllLooks')
            .then(response => response.json())
            .then(data => setLooks(data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-8">Past Looks</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {looks.map((look, index) => (
                    <LookCard key={index} look={look} />
                ))}
            </div>
        </div>
    );
};

export default PastLooks;