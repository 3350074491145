import React, {
  useEffect,
  useState,
} from 'react';

function Post({ match }) {
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`${window._env_.API_URI}/posts/${match.params.id}`);
        const data = await response.json();
        setPost(data);
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, [match.params.id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div><img src={post.storageBlobUri} /></div>
      <div>
        <span>{post.userName}</span>
        <span>{post.createdAt}</span>
        <span>{post.tags}</span>
      </div>
      <div>{post.content}</div>
    </div>
  );
}

export default Post;
