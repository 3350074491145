
import React, { useState } from 'react';
import LookOfTheDay from "../components/LookOfTheDay";
import Upload from "../components/Upload";
import UploadLooks from "../components/UploadLooks";

const LOTW = () => {
  const [showUpload, setShowUpload] = useState(false);
  const [showUploadLook, setShowUploadLook] = useState(false);

  const handleShowUpload = () => {
    setShowUpload(true);
    setShowUploadLook(false);
  };

  const handleShowUploadLook = () => {
    setShowUpload(false);
    setShowUploadLook(true);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8">Look of the Day</h1>
      <LookOfTheDay />
      <div className="flex justify-center space-x-4 my-8">
        <button
          onClick={handleShowUpload}
          className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
        >
          Upload to Existing Look
        </button>
        <button
          onClick={handleShowUploadLook}
          className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
        >
          Submit a New Look
        </button>
      </div>
      {showUpload && <Upload />}
      {showUploadLook && <UploadLooks />}
    </div>
  );
}

export default LOTW;