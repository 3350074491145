import React from 'react';

const create = () => (
  <div>
    <h1>New Post</h1>

    {/* add the upload controller to test posts */}
  </div>
);

export default create;
