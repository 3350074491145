
// LookCard.js
import React from 'react';


const LookCard = ({ look }) => {
    return (
        <div className="bg-white border border-gray-200 rounded-lg shadow-md overflow-hidden transform transition-transform hover:scale-105 mb-4">
            <div className="w-full h-64 overflow-hidden">
                <img
                    src={look.storageBlobUri}
                    alt="Look"
                    className="w-full h-full object-cover"
                />
            </div>
            <div className="p-4">
                <h2 className="font-bold text-xl mb-2 text-pink-600">{look.category}</h2>
                <p className="text-gray-700 mb-4">{look.description}</p>
                <p className="text-gray-500">Created by: {look.user?.userID}</p>
                <div className="flex justify-between items-center text-sm text-gray-500">
                    <span>{new Date(look.createdAt).toLocaleDateString()}</span>
                    <span>{look.tags}</span>
                </div>
            </div>
        </div>
    );
};

export default LookCard;