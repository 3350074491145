import React from 'react';

import { Disclosure } from '@headlessui/react';
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

const MenuButton = ({ open }) => {
  const Icon = open ? XMarkIcon : Bars3Icon;
  const additionalClasses = open? 'pt-14' : ''
  return (
    <div className="absolute inset-y-0 left-0 flex md:hidden">
      {/* Mobile menu button*/}
      <Disclosure.Button className={`inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-gray-100 hover:bg-azure-800 active:outline-none focus:outline-none ${additionalClasses}`}>
        <span className="sr-only">Open main menu</span>
        <Icon className="block h-6 w-6" aria-hidden="true" />
      </Disclosure.Button>
    </div>
  );
};

export default MenuButton;
