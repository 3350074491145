import React from 'react';

import { useMsal } from '@azure/msal-react';

const LoginButton = () => {
  const { instance } = useMsal();

  const login = () => {
    try {
      instance.loginRedirect({});
    } catch (err) {
      console.error(err);
    }
  };

  return <button onClick={login}>Login</button>;
};

export default LoginButton;
