import './index.css';

import React from 'react';

import { createRoot } from 'react-dom/client';
import {
  HashRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';

import {
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import App from './App';
import { msalConfig } from './authConfig';
import Post from './components/Post';
import EyeShapeInfo from './pages/EyeShapeInfo';
import Home from './pages/Home';
import LookOfTheWeek from './pages/LookOfTheWeek';
import PastLooks from './pages/PastLooks';
import Posts from './pages/posts';
import NewPost from './pages/posts/create';
import Profile from './pages/Profile';
import ProtectedPage from './pages/ProtectedPage';
import reportWebVitals from './reportWebVitals';
import getLogger from './utils/getLogger.js';

const logger = getLogger();
logger.debug("Initializing app…");

const msalInstance =
  await PublicClientApplication.createPublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Router>
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<Home />} />
              <Route path="eyeshapeinfo" element={<EyeShapeInfo />} />
              <Route path="/posts/:id" component={Post} />

              <Route element={<ProtectedPage />}>
                <Route path="lotw" element={<LookOfTheWeek />} />
                <Route path="profile" element={<Profile />} />
                <Route path="pastlooks" element={<PastLooks />} />
                <Route path="posts">
                  <Route index element={<Posts />} />
                  <Route path="new" element={<NewPost />} />
                </Route>
              </Route>

              {/* Invalid Path */}
              <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>Nothing to display.</p>
                  </main>
                }
              />
            </Route>
          </Routes>
        </Router>
      </MsalProvider>
    </React.StrictMode>
  );
});

const webVitalsLogger = getLogger("webVitals");
reportWebVitals(webVitalsLogger.debug);
