import React from 'react';

import { Link } from 'react-router-dom';

const Logo = () => (
  <div className="hidden flex-shrink-0 md:flex items-center ">
    <Link to="/" className="block h-16 w-auto">
      <div>
        <object
          type="image/svg+xml"
          data={process.env.PUBLIC_URL + '/images/selfmakelogo-black.svg'}
          alt="SelfMake"
          title="SelfMake"
          className="h-16 w-auto"
          style={{ pointerEvents: 'none' }}
        ></object>
      </div>
    </Link>
  </div>
);

export default Logo;
