// EyeDetail.js

import React, { useState } from 'react';

const EyeDetail = ({ detail }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="my-2">
            <button 
                className="font-semibold text-blue-600 hover:text-blue-800 focus:outline-none" 
                onClick={() => setIsOpen(!isOpen)}>
                {detail.name}
            </button>
            {isOpen && (
                <div className="p-2 border rounded mt-2">
                    <img className="h-32 w-full object-cover" src={detail.imageUrl} alt={detail.name} />
                    <p className="mt-2 text-sm">{detail.description}</p>
                    <button 
                        className="text-red-600 hover:text-red-800 focus:outline-none"
                        onClick={() => setIsOpen(false)}>
                        Close
                    </button>
                </div>
            )}
        </div>
    );
};

export default EyeDetail;
